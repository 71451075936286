.random-record-generator {
    min-height: 100vh;
    color: white;
  
    select {
      @media only screen and (max-width: 500px) {
        margin-left: 0 !important;
      }
    }

    form {
      margin-bottom: 10px;
    }
  
    .tile-wrapper {
      opacity: 1;
      transition: opacity 2s;
    
      &.hidden-wrapper {
        opacity: 0;
      }
    }

    .btn-warning {
      box-shadow: 1px 1px 3px #555;
    }

    strong.text-danger {
      display: inline-block;
      max-width: 300px;
      margin: 20px auto;
      border-radius: 5px;
      background-color: white;
    }
  
    .record-tile-container {
      max-width: 375px;
      min-height: 600px;
      padding: 12px 8px;
  
      img {
        width: 95%;
        padding: 0;
      }
  
      .desc {
        min-height: 210px;
        padding-top: 10px;
        h2 {
          font-size: 1.5em;
          font-weight: 600;
        }
        h3 {
          font-size: 1.4em;
          font-weight: 600;
          padding-bottom: 0;
        }
        h4 {
          font-size: 1.3em;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
  
      .pulsate {
        font-size: 1.5em;
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0;
      }

      .dance {
        animation-name: dance;
        animation-duration: .5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        transform-origin: 0 100%;
      }
  
      .mt-3 {
        margin-top: 0 !important;
      }

      @keyframes dance
      {
        from {
          color: purple;
        }
        to {
          color: cyan;
        }
          0%   { transform: skewX(-5deg) scaleY(1);  }
          10%  { transform: skewX(-5deg) scaleY(1);  }
          50%  { transform: skewX(0deg)  scaleY(.8); }
          90%  { transform: skewX(5deg)  scaleY(1);  }
          100% { transform: skewX(5deg)  scaleY(1);  }
      }
    }
  }