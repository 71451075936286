body {
  background-image: url('../images/vinyl-wallpaper.jpg');
  background-attachment: fixed;
  background-position: top center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  @media only screen and (max-width: 500px) {
    background-size: auto 800px;
  }

  .btn {
    font-weight: 600;
  }

  .btn-warning {
    color: #17a2b8;
    background-color: yellow;
    border-color: yellow;
  
    &:hover {
      color: black;
      background-color: yellow;
      border-color: yellow;
    }
  }

  .btn-info {
    box-shadow: 1px 1px 3px gray;
    &:not(.btn-tab) {
      &:hover {
        color: #333;
        background-color: cyan;
        border-color: cyan;
      }
    }
  }

  .btn-danger {
    box-shadow: 1px 1px 3px #555;
    &:hover {
      background-color: red;
      border-color: red;
    }
  }

  .btn-light {
    &:hover {
      background-color: #888;
      border-color: #888;
      color: white;
    }
  }

  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    @media only screen and (max-width: 500px) {
      font-size: 2.1em;
    }
  }

  hr {
    margin-top: 0;
    width: 75%;
    border-top: 1px solid white;
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
  }

  .container select.form-control {
    min-width: 175px;
  }

  #searchInput {
    border: 0;
    width: 220px;
  }

  .clear-btn {
    background-color: white;
    border: 0;
    width: 35px;
    i {
      cursor: pointer;
    }
    &:hover {
      color: red;
    }
  }

  .info-icon {
    width: 22px;
    height: 22px;
    float: right;
    margin-top: 2px;
  }

  .tooltip {
    margin-left: 7px;
  }
  .tooltip > div.tooltip-inner {
    background-color: yellow !important;
    color: black !important;
    font-weight: 500;
    font-size: 14px;;
    text-align: left;
  }

  .login-link {
    color: yellow;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    &:hover {
      color: cyan;
      text-decoration: none;
    }
  }

  .select-after-scroll {
    background-color: yellow !important;
  }

  .loading-records {
    font-size: 17px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 400px;
  }

  form label {
    font-weight: 600;
  }
}

.add-record, .edit-record {
  min-height: 100vh;
  color: white;
}

.login-page, .create-user-page {
  color: white;
}

.not-found-page {
  color: yellow;
}

.modal-screenshots {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;

  img {
    height: 250px;
    margin: 5px auto;
  }
}
