.not-found-page svg {
  width: 450px;
  height: 450px;
}

@media screen and (max-width: 600px) {
  .not-found-page svg {
    width: 350px;
    height: 350px;
  }
}

.not-found-page #label {
  fill: url(#skippingRecordGradient);
}

.not-found-page #skippingRecordGradient stop.start-color {
  stop-color: blue;
}

.not-found-page #skippingRecordGradient stop.end-color {
  stop-color: green;
}

@keyframes skip {
  100% {
    transform: rotate(30deg);
  }
}

.not-found-page #record {
  transform-origin: center center;
  animation: skip 0.8s linear infinite;
}

.not-found-page .line {
  stroke: white;
}
