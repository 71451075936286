.record-manager {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    min-width: 100px;
    color: white;

    @media (max-width: 800px) {
      .container {
        min-width: 80%;
        max-width: 90%;
      }
    }

    .manage-forms>div{
      margin-bottom: 5px;
    }
  
    .manage-forms .form-group {
      margin-bottom: 0;
      text-align: center;
      .filter-label {
        margin-bottom: 2px;
      }
    }
  
    label.record-count {
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 0;
    }
  
    .input-group {
      max-width: 300px;
    }
  
    #searchInput:focus, .btn:focus { 
      box-shadow: none;
    }
  
    .list {
      display: grid;
      margin-bottom: 50px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-column-gap: 2em;
    }
  
    .record-tile-container {
      width: 275px;
      height: 525px;
    }
  
    .hide-before-delete {
      opacity: 0;
      transition: opacity 1500ms ease-out;
    }
  
    .btn-link {
      color: yellow;
      text-decoration: none;
  
      &:hover {
        color: cyan;
      }
  
      &.to-top {
        position: fixed;
        bottom: 20px;
        left: 10px;
        @media only screen and (max-width: 600px) {
          left: 0;
        }
      }
    }

    a.btn-warning {
      box-shadow: 1px 1px 3px #555;
    }

    .record-table-head {
      background-color: white;
      color: #333;

      th {
        border: 2px solid white;
      }
    
      th.sortable {
        cursor: pointer;
        i.fa {
          margin-left: 4px;
        }
        &:hover {
          background-color: #eef;
        }
      }
    
      th.button-column {
        min-width: 80px;
        text-align: center;
      }
    }
    
    table.table {
      margin-top: 10px;
    }

    .record-table-body {
      background-color: gray;

      tr {
        transition: background-color 1s ease;
      }
    
      td {
        border: 2px solid white;
        vertical-align: middle;
        padding-left: 4px;
    
        a.album-link {
          color: cyan;
          &:hover {
            color: yellow;
            text-decoration: none;
          }
        }

        .btn-info {
          box-shadow: 1px 1px 2px #222;
        }
      }
    
      .cover-thumbnail {
        padding-right: 2px;
      }
    }
  }