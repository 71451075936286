svg {
  width: 450px;
  height: 450px;
}

@media screen and (max-width: 600px) {
  svg {
    width: 350px;
    height: 350px;
  }
}

#label {
  fill: url(#yellowRedGradient);
}

#yellowRedGradient stop.start-color {
  stop-color: yellow;
}

#yellowRedGradient stop.end-color {
  stop-color: red;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(360deg);
  }
}

#record {
  -webkit-transform-origin: center center;;
  transform-origin: center center;
  animation: spin 4s linear infinite;
}

.line {
  stroke: white;
}
