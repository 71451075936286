.record-tile-container {
    margin: 20px auto;
    background-color: white;
    position: relative;
    border-radius: 3px;
    transition: background-color 1s ease;
  
    .star-fav {
      position: absolute;
      right: 2%;
      font-size: 30px;
      color: yellow;
      text-shadow: 1px 1px 10px #888, 1px 1px 10px #888;
    }
  
    img {
      width: 100%;
      min-height: 274px;
      padding: 8px;
    }
  
    .no-img {
      width: 100%;
      height: 275px;
      color: pink;
      padding: 50px 15px;
    }
  
    .tile-footer {
      position: absolute;
      bottom: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .btn {
        width: 80%;
      }
    }
  
    .desc {
      padding: 5px 7px;
      text-align: center;
      position: relative;
      min-height: 175px;
  
      a, a:visited {
        color: darkcyan;
        &:hover {
          color: cyan;
          text-decoration: none;
        }
      }
  
      h2 {
        color: orangered;
        font-size: 1.2em;
        font-weight: 600;
      }
  
      h3 {
        color: black;
        font-size: 1.15em;
        font-weight: 600;
      }
  
      h4 {
        color: black;
        font-size: 1.1em;
        font-weight: 400;
        margin-bottom: 7px;
        &.more-info {
          position: absolute;
          left: 0;
          width: 100%;
        }
      }
    }
  }